.side-menu {
	background: linear-gradient(90deg, var(--softColor1), #ffffff);
	border-right: 1px solid rgba(0, 0, 0, 0.1);
	padding-right: 0;
	position: sticky !important;
	height: 100vh;
	overflow-y: scroll;
	top: 0;

	.nav-item {
		-webkit-transition: background-color 300ms linear;
		-ms-transition: background-color 300ms linear;
		transition: background-color 300ms linear;

		&:hover,
		&.active {
			// background: linear-gradient(90deg, #ffffff, var(--softColor1));
			background-color: var(--softColor1);

			a {
				color: var(--textColor);
			}
		}
	}
}