.data-viewer {
	.viewer-header,
	.viewer-content .viewer-data {
		display: grid;
		grid-template-columns: 140px 250px 220px 150px 150px;
		grid-column-gap: 22px;
	}

	&.data-affiliates-admin {
		.viewer-header,
		.viewer-content .viewer-data {
			grid-template-columns: auto 100px  100px 100px 100px 100px 120px;
		}
	}
	&.data-business-users-admin {
		.viewer-header,
		.viewer-content .viewer-data {
			grid-template-columns: auto 220px 220px 140px 140px;
		}
	}

	&.data-reports-admin {
		.viewer-header,
		.viewer-content .viewer-data {
			grid-template-columns: auto 100px  100px 100px 100px 100px 120px;
		}
	}

	&.data-affiliates-affiliate {
		.viewer-header,
		.viewer-content .viewer-data {
			grid-template-columns: auto 220px 160px 160px;
		}
	}

	// Column style for affiliate view of admin

	.viewer-header {
		background: #CCCCCC;
		border: 1px solid #707070;
		padding: 12px;

		.header-item {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.item-left {
				.item-name {
					color: inherit;
				}

				.item-search {
					display: none;
				}
			}

			.item-action {
				display: grid;
				grid-template-columns: auto auto;
				grid-column-gap: 8px;

				& > div {
					cursor: pointer;
				}

				.action-search {

				}

				.action-sort {

				}
			}
		}
	}

	.viewer-content {
		word-wrap: break-word;

		.viewer-data {
			padding: 8px 12px;
			border-bottom: 1px solid #EDEDED;

			.data-single {
				.single-content {
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					position: relative;
				}
			}
		}

		.card-links {
			display: grid;
		}
	}
}


@media (max-width: 768px) {
	.data-viewer {
		.viewer-header {
			display: none;
		}

		&.data-users-admin {
			.viewer-header,
			.viewer-content .viewer-data {
				grid-template-columns: 36% 61%;
				grid-column-gap: 8px;
			}

			.viewer-data {
				.data-single {
					&.data-cards {
						grid-column: 1/ span 2;
					}

					.single-content {
						text-overflow: ellipsis;
						white-space: nowrap;
						overflow: hidden;
					}
				}
			}
		}

		&.data-affiliates-admin {
			.viewer-header,
			.viewer-content .viewer-data {
				grid-template-columns: 48% 48%;
				grid-column-gap: 8px;
			}

			.viewer-data {
				.data-name {
					grid-column: 1/span 2;

					.single-content {
						text-align: left;
					}
				}

				.single-content {
					text-align: right;

					&:before {
						content: attr(data-label);
						position: absolute;
						left: 0;
						width: 50%;
						font-size: 15px;
						text-align: left;
					}
				}
			}
		}


		&.data-reports-admin {
			.viewer-header,
			.viewer-content .viewer-data {
				grid-template-columns: auto;
				grid-column-gap: 8px;
			}

			.viewer-data {
				.data-single {
					border-bottom: 1px solid rgba(0, 0, 0, 0.04);
					&.data-title {
						//grid-column: 1/ span 2;

						.single-content {
							//text-align: left;
						}
					}

					.single-content {
						text-align: right;

						&:before {
							content: attr(data-label);
							position: absolute;
							left: 0;
							width: 50%;
							font-size: 15px;
							text-align: left;
						}
					}
				}
			}
		}

		&.data-affiliates-affiliate {
			.viewer-header,
			.viewer-content .viewer-data {
				grid-template-columns: auto;
				grid-column-gap: 8px;
			}

			.viewer-data {
				.data-single {
					border-bottom: 1px solid rgba(0, 0, 0, 0.04);
					&.data-title {
						//grid-column: 1/ span 2;

						.single-content {
							//text-align: left;
						}
					}

					.single-content {
						text-align: right;

						&:before {
							content: attr(data-label);
							position: absolute;
							left: 0;
							width: 50%;
							font-size: 15px;
							text-align: left;
						}
					}
				}
			}
		}

		&.data-users-affiliate {
			.viewer-header,
			.viewer-content .viewer-data {
				grid-template-columns: auto;
				grid-column-gap: 8px;
			}

			.viewer-data {
				.data-single {
					border-bottom: 1px solid rgba(0, 0, 0, 0.04);

					&.data-name,
					&.data-cards {
						//grid-column: 1/ span 2;
						.single-content {
							text-align: left;
						}
					}

					.single-content {
						text-align: right;

						&:before {
							content: attr(data-label);
							position: absolute;
							left: 0;
							width: 50%;
							font-size: 15px;
							text-align: left;
						}
					}
				}
			}
		}

		&.data-business-users{

		}
		&.data-business-prospects{

		}
	}

}