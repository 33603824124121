.announcements {
	.single {
		overflow: hidden;
		border-radius: 10px;
		margin-bottom: 1rem;

		.single-content {
			padding: calc(1vh);
			display: flex;
			align-items: center;

			.icon {
				i {
					font-size: 26px;
				}
			}

			.details {
				padding: 0 8px;

				.title {
					font-size: 12px;
				}

				.content {
					font-size: 14px;
				}
			}
		}

		.single-footer {
			background-color: rgba(0, 0, 0, .03);
			padding: 0.22rem 1rem;
			font-size: 80%;
			display: flex;
			justify-content: space-between;
		}
	}
}