body {

	.bg-primary {
		background-color: var(--primaryColor) !important;
	}

	.btn-primary {
		background-color: var(--primaryColor);
		border-color: var(--primaryColorDark);

		&:hover {
			background-color: var(--primaryColorDark);
		}
	}

	.btn-outline-primary {
		color: var(--primaryColor);
		border-color: var(--primaryColor);

		&:hover {
			background-color: var(--primaryColor);
		}
	}

	.table-light,
	.table-light>th,
	.table-light>td {
		background-color: var(--softColor1);
	}


	.list-panels .table tbody th,
	.list-panels .table tbody td,
	.list-items .table tbody th,
	.list-items .table tbody td {
		padding: calc(1rem / 1.618) 12px;
	}

	.btn-primary:focus,
	.btn-primary.focus {
		background-color: var(--primaryColorDark);
		border-color: var(--primaryColor);
		box-shadow: none;
	}

	.btn-primary:not(:disabled):not(.disabled):active,
	.btn-primary:not(:disabled):not(.disabled).active,
	.show>.btn-primary.dropdown-toggle {
		background-color: var(--primaryColorDark);
		border-color: var(--primaryColor);
	}

	.dropdown-item.active,
	.dropdown-item:active {
		background-color: var(--primaryColor);
	}

	.drop-left {
		left: -64px !important;
	}

	.form-control {
		&:focus {
			border-color: var(--primaryColor);
			box-shadow: 0 0 0 0.2rem var(--softColor1);
		}
	}

	.btn-primary:not(:disabled):not(.disabled):active:focus,
	.btn-primary:not(:disabled):not(.disabled).active:focus,
	.show>.btn-primary.dropdown-toggle:focus {
		box-shadow: 0 0 0 0.2rem var(--softColor1);
	}


	@media (max-width: 768px) {

		.list-panels .table tbody th,
		.list-panels .table tbody td,
		.list-items .table tbody th,
		.list-items .table tbody td {
			padding: 0.381rem;
		}
	}
}