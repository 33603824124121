.navbar {
	&.navbar-light {
		// background-color: var(--primaryColor) !important;
		background: linear-gradient(45deg, var(--secondaryColor), var(--primaryColor));


		.navbar-nav {
			.nav-link {
				color: var(--textColorLight);
			}

			.nav-item {
				&.active {
					.nav-link {
						color: #fff;
					}
				}
			}
		}
	}
}

.sidebar-nav {
	.nav-item {
		&.active {
			color: var(--primaryColor);
		}
	}

	:first-child {
		.nav-link {
			// padding-top: 0;
		}
	}

	.nav-link {
		color: var(--textColor);
		padding: calc(1rem / 1.618) 1rem;
		display: flex;
		align-items: center;

		.icon-wrapper {
			width: 1rem;
			display: flex;

			i {
				font-size: 20px;
				// background: var(--textColor);
				// color: white;
			}
		}

		.icon-text {
			padding-left: 10px;
		}
	}
}



@media (max-width: 768px) {
	.navbar {
		.mobile-show{
			.nav-item{
				.dropdown-item{
					color: rgba(255, 255, 255, 0.68);
					padding: 0;
				}
			}
		}
	}
}