.page-auth{
	// background-color: var(--primaryColor);
	.page-content{
		background-color: #F9F9F9;
		padding         : 10vh 12vh;
		min-height      : 100vh;
		box-shadow      : 3px 0 28px hsla(0,0%,45.5%,.5);

		.auth-brand{
			display      : block;
			text-align   : center;
			margin-bottom: 6vh;

			.logo {
				max-width: 180px;
			}
		}

		.login-preview {
			overflow   : hidden;
			display    : flex;
			align-items: center;
		}

		.login-preview img {
			max-width: 100%;
		}

		.form-support {
			display: flex;
			justify-content: space-between;
		}

		.form-support a {
			font-style: italic;
			text-decoration: underline;
			color: inherit;
		}

		.area-support-links{
			a{
				color: inherit;
				text-decoration: underline;
			}
		}
	}

	.preview-section{
		display: flex;
		align-items: center;
		justify-content: space-evenly;

		h1{
			font-size: 92px;
			text-align: center;
			color: white;
		}
	}
}

.page-auth-checking {
    min-height: 100vh;
    background-color: var(--primaryColor);
    display: flex;
    justify-content: center;
	align-items: center;

	.checking-content{
		text-align: center;
	}

	.title,
	.footer{
		color: #dadada;
	}

	.area-logo {
		margin-bottom: 5vh;
	}
	.logo{
		-webkit-filter: grayscale(100%);
		filter        : grayscale(100%);
	}
}