.new-order {
	.service-description {
		border: 1px solid #ececec;
		padding: 0.375rem 0.75rem;
		background: #f8f8f8;
		border-radius: 0.25rem;
		margin-bottom: 1rem;
	}

	.charge-info {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.total-charge {
		font-size: 32px;
		font-weight: bold;
		margin-left: 8px;

		.charge-decimal {
			font-size: 1rem;
		}
	}

	.form-actions {
		justify-content: flex-end;
	}
}