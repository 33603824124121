.affiliate-detailed-report {
	.report-header {
		display: grid;
		padding: 15px 0;
		grid-template-columns: 20% 60% 20%;
		align-items: center;

		.action-left {
			display: grid;
			grid-template-columns: auto auto;
			grid-gap: 12px;

			.action-single {
				display: flex;
				flex-direction: column;
				text-align: center;
				border: 1px solid black;
				padding: 10px;
				cursor: pointer;

				.icofont-printer {

				}
			}
		}

		.title-section {
			display: flex;
			flex-direction: column;
			align-items: center;

			h3 {
				margin: 0;
			}

			p {
				margin: 0;
			}
		}

		.action-right {
			display: flex;
			justify-content: flex-end;
			align-items: center;

			i {
				padding: 12px;
				font-size: 2rem;
			}
		}
	}

	.report-section {
		border: 1px solid black;
		margin-bottom: 1.618rem;

		.section-head {
			background: #CCCCCC;
			border-bottom: 1px solid #707070;
			text-align: center;
			padding: 8px;

			.double-title {
				display: flex;
				justify-content: space-between;
				padding: 12px 18px;
			}
		}

		.section-content {
			background: white;
			padding: 12px 18px;

			.report-summery {
				padding: 1rem 6rem 3rem;

				.summery-single {
					display: flex;
					border-bottom: 1px solid #e6e6e6;
					justify-content: space-between;
					padding: 6px 0;
				}
			}
		}

		.single-details {
			padding: 3rem;
			display: grid;
			grid-template-columns: 60% 32%;
			grid-gap: 10%;

			.commission-summery {
				.table th, .table td {
					padding: 0.2rem;
				}
			}

			.commission-action {
				display: grid;
				grid-template-columns: 49% 49%;
				grid-gap: 2%;

				.action-summery {
					grid-column: 1/ span 3;
					text-align: center;
					font-weight: bold;
				}

				.action-single {
					display: flex;
					flex-direction: column;
					align-items: center;
					text-align: center;
					cursor: pointer;
					border: 1px solid black;
					justify-content: center;
					position: relative;

					i.big {
						font-size: 2.5rem;
					}

					i.marked-as-done {
						position: absolute;
						right: -8px;
						top: -8px;
						width: 26px;
						height: 26px;
						display: flex;
						align-items: center;
						justify-content: center;
						background: #39E46E;
						color: white;
						border-radius: 50%;
						padding: 6px;
					}
				}
			}
		}
	}
}


.agent-affiliate-detailed-report {
	.report-section {
		border: 1px solid black;
		margin-bottom: 1.618rem;

		.section-head {
			background: #CCCCCC;
			border-bottom: 1px solid #707070;
			text-align: center;
			padding: 8px;

			.double-title {
				display: flex;
				justify-content: space-between;
				padding: 12px 18px;
			}
		}

		.section-content {
			background: white;
			padding: 12px 18px;

			.report-summery {
				padding: 1rem 6rem 3rem;

				.summery-single {
					display: flex;
					border-bottom: 1px solid #e6e6e6;
					justify-content: space-between;
					padding: 6px 0;
				}
			}
		}

		.single-details {
			padding: 3rem;
			display: grid;
			grid-template-columns: 60% 32%;
			grid-gap: 10%;

			.commission-summery {
				.table th, .table td {
					padding: 0.2rem;
				}
			}

			.commission-action {
				display: grid;
				grid-template-columns: 100%;

				.action-summery {
					grid-column: 1/ span 3;
					text-align: center;
					font-weight: bold;
				}

				.action-single {
					display: flex;
					flex-direction: column;
					align-items: center;
					text-align: center;
					cursor: pointer;
					border: 1px solid black;
					justify-content: center;
					position: relative;

					i.big {
						font-size: 2.5rem;
					}

					i.marked-as-done {
						position: absolute;
						right: -8px;
						top: -8px;
						width: 26px;
						height: 26px;
						display: flex;
						align-items: center;
						justify-content: center;
						background: #39E46E;
						color: white;
						border-radius: 50%;
						padding: 6px;
					}
				}
			}
		}

		.show-users {
			padding: 0 3rem 3rem 3rem;
		}
	}
}

@media (max-width: 768px) {
	.affiliate-detailed-report {
		.report-header {
			grid-template-columns: 80% 20%;

			.action-left {
				display: none;
			}

			.title-section {
				align-items: flex-start;
			}

			.action-right {
				i {
				}
			}
		}

		.report-section {
			padding-bottom: 52px;

			.section-head {

				.double-title {
					padding: 0;
					flex-direction: column;
					align-items: flex-start;
				}
			}

			.section-content {
				padding: 8px;

				.report-summery {
					padding: 0;

					.summery-single {
					}
				}
			}

			.single-details {
				padding: 8px;
				grid-template-columns: auto;

				.commission-summery {
					.table th, .table td {
					}
				}

				.commission-action {
					.action-summery {
					}

					.action-single {
						padding: 8px;
					}
				}
			}
		}
	}

}