@font-face {
	font-family: 'SFPro-Regular';
	src: local('SFPro-Regular'), ur('../../fonts/SFProDisplay-Regular.ttf') format('truetype');
}

body {
	// font-size: 14px;
	// background: #f9f9f9;
	background: linear-gradient(90deg, #ececec, #ffffff);

	font-family: 'SFPro-Regular';
	color: var(--textColor1);

}

.h1,
h1 {
	font-size: 20px;
}

a,
.a {
	color: var(--primaryColor);
	cursor: pointer;

	&:hover {
		color: var(--primaryColorDark);
	}
}

a.normal {
	color: inherit;
}

.cursor-pointer {
	cursor: pointer;
}

.bg-primary {
	background: var(--primaryColor) !important;
}

#root {
	&>div>.row {
		// margin-right: 0;
	}
}


.color-primary {
	color: var(--primaryColor);
}

.navbar-brand {
	.logo {
		max-width: 120px;
	}
}

.page-auth {
	background: linear-gradient(90deg, var(--primaryColor), var(--secondaryColor));
}

.text-bold {
	font-weight: bold;
}

.page-content {
	//border: .1rem solid var(--softColor1);
	// border-radius: .6rem;
	box-shadow: 0 0.5rem 0.5rem rgba(13, 21, 29, .05);
	//padding: 2rem 1.5rem;
	// margin-bottom: 6vh;
	background-color: #fff;
	// background: linear-gradient(185deg, var(--softColor1), #ffffff);

}

.action-column {
	min-width: 120px;
}

.section-pagination {
	display: flex;
	align-items: center;
	justify-content: space-between;

	.pagination-custom {
		width: 62px;
		padding: 6px 3px;
		margin-right: 6px;
		margin-left: 6px;
		text-align: center;
		border-radius: 5px;
		border: 1px solid #dee2e6;
	}

	.pagination-nav {
		display: inline-block;

		ul {
			margin: 0;
		}

		.page-item {
			&.active {
				.page-link {
					z-index: inherit;
					color: var(--softColor1);
					background-color: var(--primaryColor);
					border-color: var(--primaryColorDark);
				}
			}

			.page-link {
				color: var(--primaryColor);
				padding: 0.2rem 0.75rem;

				&:focus {
					box-shadow: none;
				}
			}
		}
	}
}

.page-header {
	// margin-top: 5vh;
	padding-bottom: 16px;
	display: flex;
	justify-content: space-between;

	.header-title {
		display: flex;
		align-items: center;
		//margin-bottom: 1rem;
		justify-content: space-between;
	}

	.header-title h1 {
		margin: 0;
		color: #4A4A4A;
	}
}

.table-area,
.list-items {
	.table {
		color: var(--textColor1);

		thead th {
			border: 0;
		}

		tbody {

			th,
			td {
				padding: 21px 12px;
			}

			.level-status {
				background-color: var(--primaryColor);
				color: white;
				border-radius: 21px;
				padding: 3px 12px;

				&.success {
					background-color: #7BE3A7;
				}

				&.pending {
					background-color: #FFE4C2;
					color: #4d4d4d
				}

				&.cancelled {
					background-color: #CBCBCB;
				}
			}
		}

		.disabled th,
		.disabled td {
			color: #cbcbcb;
		}
	}
}

.table-area {
	.table-filter {
		margin-bottom: 1rem;


		.filter-advance {
			border: 1px solid gray;
			background-color: var(--softColor1);
			padding: 0.618vw;
			margin-top: 1vw;
		}
	}
}

.insert-new {
	.single-section {
		margin-bottom: 2rem;

		.section-title h4 {
			font-size: 18px;
			font-weight: bold;
			margin-bottom: 18px;
		}
	}

	.form-actions {
		display: flex;
		justify-content: space-between;
		align-items: center;

		&.update{
			justify-content: flex-end;
		}

		.back-btn {
			font-size: 20px;
			color: var(--primaryColor);
		}
	}
}

.Collapsible {
	border-radius: calc(1rem / 1.618);
	overflow: hidden;
	margin-bottom: calc(1rem / 1.618 / 1.618);

	.Collapsible__trigger {
		display: block;
		cursor: pointer;
		background-color: var(--primaryColor);
		color: white;
		padding: calc(1rem / 1.618) 1rem;
	}

	.Collapsible__contentOuter {
		background: linear-gradient(90deg, #ffffff, var(--softColor1));

		.Collapsible__contentInner {
			padding: calc(1rem / 1.618);
			border: 1px solid;
			border-radius: 0 0 calc(1rem / 1.618) calc(1rem / 1.618);
		}
	}
}

.date-view {
	width: 160px;

	.exact-time {
		display: none;
	}

	&:hover {
		.exact-time {
			display: block;
		}

		.time-ago {
			display: none;
		}
	}
}

.data-table {
	thead {
		th {
			position: relative;

			.sortable {
				position: absolute;
				right: 16px;
				cursor: pointer;

				&:not(.active) {
					color: #2c3e516b;
				}

				&:hover {
					color: inherit;
				}
			}
		}
	}
}


.user-details {
	.user-cards {
		.thumbnail {
			width: 28px;
			height: 28px;
			border-radius: 50%;
		}

		.qr-icon{
			margin-right: 8px;
			cursor: pointer;
		}
	}
}

.dynamic-links {
	border: 1px solid #707070;
	border-radius: 6px;
	padding: 8px 6px;
}

.promo-codes {
	display: grid;
	grid-template-columns: auto auto auto auto;
}

.react-confirm-alert-overlay {
	background-color: rgba(1, 19, 49, 0.68) !important;
}

.users-summery {
	display: grid;
	grid-template-columns: auto auto auto auto auto auto auto;
	grid-gap: 16px;

	.summery-single {
		text-align: center;

		.count {
			font-weight: bold;
			font-size: 18px;
		}
	}
}

.page-affiliates{
	.header-title{
		display: flex;
	}
}