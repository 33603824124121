.ReactModal__Content {
	width: 60% !important;
	//max-width: 80% !important;
	top: 10vh !important;
	//bottom: auto !important;
	margin: auto;
	text-align: center;
	max-width: 80%;
}

.ReactModal__Overlay {
	background-color: rgba(1, 19, 49, 0.68) !important;
}

.basic-modal {
	.modal-header {
		position: relative;
		padding: 0;
		margin-bottom: 3vh;

		h3 {

		}

		.close-modal {
			position: absolute;
			padding: 0;
			right: -12px;
			top: -14px;

			i {
				font-size: 2rem;
			}
		}
	}

	.modal-content {
		border: none;
		text-align: left;
	}
}

@media (max-width: 768px) {
	.ReactModalPortal{
		.ReactModal__Content{
			margin: 0;
			//padding: 0px !important;
			width: auto !important;

			.section-head{
				.double-title{
					padding: 0;
					flex-direction: column;
				}
			}
			.single-details{
				padding: 0;
				grid-template-columns: auto;
			}

			.report-section{
				border: none;
			}
			.commission-action{
				.action-single{
					padding-top: 8px;
				}
			}

		}
	}
}