.details-ticket {

	.con-actions {
		margin-top: 1rem;
		padding-top: 1rem;
		margin-bottom: 1rem;
		border-bottom: 1px solid var(--softColor1);

		.message {
			margin-bottom: 1rem;

			textarea {
				resize: none;
			}
		}

		.buttons {
			button {
				padding: .381rem 2rem;
			}
		}
	}

	.ticket-info {
		border-left: 1px solid var(--softColor1);

		.info-header {
			border-bottom: 1px solid var(--softColor1);
			margin-bottom: 0.618rem;
		}

		.info-body {
			.info-single {
				display: flex;
				justify-content: space-between;

				.info-title {}

				.info-value {}
			}
		}
	}

	.ticket-details {
		.ticket-title {
			border-bottom: 1px solid var(--softColor1);
			padding-bottom: 0.618rem;
			margin-bottom: 1rem;
		}

		.ticket-conversations {

			.con-single {
				margin-bottom: 1rem;
				display: flex;
				border-bottom: 1px dotted var(--softColor1);
				padding-bottom: 1rem;

				&:last-child {
					border-bottom: none;
					padding-bottom: 0;
				}

				.user-favicon {
					min-width: 36px;
					height: 36px;
					background: var(--primaryColor);
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 50%;
					color: white;
					font-weight: bold;
					margin-right: 1rem;
				}

				.message {
					width: calc(100% - 36px);

					.message-header {
						display: flex;
						justify-content: space-between;
						border-bottom: 1px dotted var(--softColor1);

						.user-name {
							font-weight: bold;
						}

						.con-time {
							text-align: right;
						}
					}
				}
			}
		}
	}
}