.templates{
	color: #707070;

	.template-single{
		text-align: center;
		.template-title{

		}

		.template-content{
			background: white;
			border: 1px solid #707070;
			.card-cover{

			}
			.card-basic{
				.card-business{
					font-size: 22px;
				}
				.card-name{
					font-size: 18px;

				}
				.card-title{
					//font-size: 24px;
				}
			}
			.card-tabs{

				.tab-single{
					background: #CCCCCC;
					border: 1px solid #707070;
					padding: 20px;
					margin-bottom: 6px;

					&:last-child{
						margin-bottom: 0px;
						border-bottom: none;
					}

					.tab-title{
					}

					& > a{
						color: blue;
					}
				}
			}
		}
	}
}