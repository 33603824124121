.details-invoice {
	color: #707070;
	font-size: 14px;

	.party-details {
		display: flex;
		justify-content: space-between;

		.from-info,
		.to-info {
			border: 1px dotted #dee2e6;
			padding: 12px;
			width: calc(50% - 10px);
			border-radius: 16px;

			.name {
				font-weight: bold;
				font-size: 18px;
			}
		}
	}

	.invoice-header {
		display: flex;
		justify-content: space-between;
		// font-size      : 18px;
		padding: 16px;

		.invoice-id {}

		.due-date {}
	}

	.invoice-items {
		margin-top: 16px;
		padding: 0 16px;

		.item-header {
			display: flex;
			justify-content: space-between;
			font-size: 14px;
			font-weight: bold;
			padding: 8px 6px;
			border-bottom: 1px solid #e2e2e2;
			border-top: 1px solid #e2e2e2;
			background: #f9f9f9;
		}

		.single-item {
			display: flex;
			justify-content: space-between;
			font-size: 14px;
			padding: 8px 6px;
			border-bottom: 1px solid #e2e2e2;

			&:last-child {
				// border-bottom: none;
			}
		}

		.info-name {
			width: 40%;
		}

		.info-rate,
		.info-tax,
		.info-value,
		.info-quantity {
			width: 15%;
		}

		.info-value {
			text-align: right;
		}
	}

	.invoice-summery {
		justify-content: space-between;
		font-weight: bold;
		margin-top: 28px;
		padding: 12px 16px 0;
		padding-left: 55%;
		font-size: 14px;

		li {
			list-style: none;
			display: flex;
			border-bottom: 1px solid #e2e2e2;
			padding: 12px 6px;
			font-weight: normal;
			justify-content: space-between;
		}

		.summery-title {
			border-bottom: 1px solid #e2e2e2;
			border-top: 1px solid #e2e2e2;
			background: #f9f9f9;
			text-align: center;
			font-weight: bold;
			justify-content: center;
		}

		.summery-name {
			font-weight: bold;
		}
	}

	.invoice-actions {
		margin-top: 80px;
		padding: 0 16px;

		.exists-card {
			margin-left: 8px;
			border: 1px solid #ced4da;
		}

		.action-title {
			font-size: 18px;
			font-weight: bold;
			margin-bottom: 27px;
		}
	}
}